@import "./styles/mixings.scss";
:root {
  --primary-color-dark: #fff;
  --primary-color-light: #fff;
  --secondary-color-dark: #fff;
  --secondary-color-light: #fff;
  --background-color-grey: #fff;
  --background-color-dark: #612263;

  --color-grey-dark: #999999;
  --color-grey-medium: #cccccc;
  --color-grey-light: #e0e0e0;
  --color-black: #000;
  --color-white: #fff;

  --panels-background-dark: #f7f8ff;
  --panels-background-light: #ffffff;

  --bedges-blue: #e8f6fe;
  --bedges-green: #ade6c8;

  --border-color: #f4f4f4;
  --border-radius: 10px;
  --border-radius-small: 4px;
  --border-radius-medium: 10px;
  --border-radius-large: 50%;

  --active-background: #b5d2f7;
  --active-color: #3c3c3c;

  --section-padding: 1rem;
  --section-margin: 1rem; //section is parent of block
  --section-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);

  --block-margin: 0.5rem; //block is child of scetion
  --block-padding: 0.5rem;
  --section-shadow: 0 0.5rem 0.3rem rgb(0 0 0 / 15%);

  --button-margin: 0.5rem;
  --link-margin: 0.5rem;
  --form-field-margin: 0.5rem;

  --font-family-content: "Open Sans", sans-serif;
  --font-family-headers: "Poppins", sans-serif;

  --chart-green: #5f8672; //chart line
  --chart-green-light: #ade6c8; //
  --chart-red: #db614f; //chart line
  --chart-red-light: #f8a79b; //

  --text-color: #3c3c3c;
  --text-bold-color: #000000;
  --text-color-light: rgb(228, 174, 230);
  --text-color-pale: #808080;

  --footer-link-color: #000;

  --retail-catalog-desc-bg-color: rgba(254, 254, 254, 0.6);
  --retail-catalog-desc-text-color: #000;

  --text-size-small: 0.75rem;
  --text-size-medium: 0.9rem;
  --text-size-large: 1rem;
  --text-size-xl: 4rem;
  --text-weight-content: 400;
  --text-weight-titles: 700;

  --text-line-height: 1.5rem;
  --background-image: url("/assets/fengshui-background.png");
}

// @include media-breakpoint-min(xs) { width: 100% ;}
// @include media-breakpoint-max(sm) { width: 100%; }
// @include media-breakpoint-between(md, lg) { width:100%; }
// @include media-breakpoint-max(xlg) { width: 100%; }

body {
  animation: fadeInBody 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  background: var(--primary-color-dark);
  color: var(--primary-color-light);
  background-image: var(--background-image);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;

  @include media-breakpoint-max(md) {
    background-size: cover;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  img {
    border-radius: 10px;
    box-shadow: 0 20px 50px 0 rgba(28, 9, 80, 0.15);
  }
  #root > main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    min-height: 350px;
    .main {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-grow: 1;
      min-height: 350px;
      &.contact {
        background-image: url(/assets/kristina2.png);
        background-position: bottom right;
        background-repeat: no-repeat;
      }
      // &.about {
      //   background-image: url(/assets/dofeng2.png);
      //   background-position: bottom right;
      //   background-repeat: no-repeat;
      // }
      // &.workshop {
      //   background-image: url(/assets/kristina3.png);
      //   background-position: bottom right;
      //   background-repeat: no-repeat;
      // }
      &.referenzen {
        background-image: url(/assets/kristina9.png);
        background-position: bottom right;
        background-repeat: no-repeat;
      }
      &.preise {
        background-image: url(/assets/kristina.png);
        background-position: bottom right;
        background-repeat: no-repeat;
      }
      &.links {
        background-image: url(/assets/kristina8.png);
        background-position: bottom right;
        background-repeat: no-repeat;
      }
      &.home {
        .card img {
          aspect-ratio: 8/8;
          border-radius: 67% 72%;
          border: 5px solid var(--primary-color-light);
          width: 50%;
          margin-left: 25%;
        }
      }
    }
  }
}
h1,
h2,
h3 {
  font-family: "Roboto", sans-serif;
  text-align: left;
}

.container {
  @include media-breakpoint-max(sm) {
    //background-color: rgba(#151520, 0.5);
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    padding: 1rem;
  }
}
.container {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(3, 3, 42, 0.05085784313725494) 50%,
    rgba(0, 212, 255, 0) 100%
  );
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.navbar-toggler {
  @include media-breakpoint-max(md) {
    background-color: var(--secondary-color-dark) !important;
  }
}

.h-20 {
  @include media-breakpoint-min(lg) {
    height: 20rem;
  }
}
.h-30 {
  @include media-breakpoint-min(lg) {
    height: 30rem;
  }
}
.h-50 {
  @include media-breakpoint-min(lg) {
    height: 38rem !important;
  }
}
.h-75 {
  @include media-breakpoint-min(lg) {
    height: 77rem !important;
  }
}
.h-15 {
  @include media-breakpoint-min(lg) {
    height: 15rem;
  }
}
a {
  color: var(--text-color-light);
}

.btn.btn-primary {
  border: 1px solid var(--primary-color-dark);
  color: var(--primary-color-dark) !important;
  background: var(--secondary-color-dark);
  &:hover {
    border: 1px solid var(--color-white);
  }
}

.center {
  text-align: center;
}
.login {
  z-index: 50;
  background-color: var(--color-white);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn.btn-primary {
  background-color: var(--secondary-color-dark) !important;
  color: var(--primary-color-dark) !important;
  &:hover {
    border: 1px solid var(--color-white);
  }
}
.login-page-form {
  background-color: var(--primary-color-dark);
  .title {
    padding: 1rem 0;
    font-size: var(--text-size-xl);
  }
  .login-links a {
    padding: 1rem 0;
    color: var(--color-white);
  }
  .login-button {
    margin: var(--button-margin);
    width: 10rem;
    background-color: var(--secondary-color-dark);
  }
  button {
    background-color: var(--color-white) !important;
    color: var(--color-black);
  }
  input[type="text"],
  [type="password"] {
    @media (min-width: 772px) {
      width: 40%;
    }
    @media (min-width: 576px) and (max-width: 771px) {
      width: 70%;
    }
  }
}
.cart-badge-header {
  position: absolute;
  top: -0.75rem;
  left: 0.75rem;
}
.cart-item-badge {
  position: relative;
  bottom: 1rem;
  left: 4rem;
}
.cart-products-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #dee2e6;
  margin: 3rem 0;
  @media (max-width: 992px) {
    display: none;
  }
}
.component {
  h2 {
    color: var(--primary-color-light);
    text-align: center;
  }
  a.button {
    color: var(--secondary-color-dark);
    border: 1px solid var(--secondary-color-dark);
    background: var(--primary-color-dark);
    padding: 0.5rem;
    margin: 0.3rem;
    border-radius: 5px;
    text-align: center;
    i {
      color: var(--primary-color-light);
    }
  }
}
@media (min-width: 576px) {
  .login-header {
    display: none;
  }
}
.display-form {
  position: absolute;
  height: 17rem;
  right: 1rem;
  width: 15rem;
  button {
    background-color: var(--primary-color-dark) !important;
    color: var(--color-white);
    &:hover {
      background-color: var(--primary-color-dark) !important;
    }
  }
}
@media (max-width: 576px) {
  .display-form {
    display: none;
  }
}
.App {
  text-align: center;
}
.logo {
  height: 5rem;
  margin: 0.5rem 0;
}
.logo-retail {
  height: 3rem;
  margin: 0.5rem 0;
}
.font-white {
  color: var(--color-white);
}
.font-black {
  color: var(--color-black);
}
.font-secondarycolor {
  color: var(--secondary-color-light);
}
.btn {
  @include media-breakpoint-max(md) {
    width: 100% !important;
  }
}
.banner-card {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(3, 3, 42, 0.05085784313725494) 50%,
    rgba(0, 212, 255, 0) 100%
  );
  padding: 1rem;
}
.banner {
  position: relative;
  display: block;
  margin-bottom: 1rem;
  background-repeat: no-repeat !important;
  @include media-breakpoint-max(lg) {
    min-height: 30rem;
  }
  .banner-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    padding: 1rem 3rem;
  }
  .banner-blurb {
    position: relative;

    // @include media-breakpoint-min(xlg) { width: 50%; }
  }
  .banner-button {
    position: relative;
    width: fit-content;
    top: 0.5rem;
    padding: 0.3rem 2rem;
  }
}
.container {
  &.banner {
    margin-bottom: 3rem;
  }
  .banner-content {
    padding: 0;
    &.center {
      width: 50%;
      align-items: center;
      margin: 0 25%;
      @include media-breakpoint-max(md) {
        width: 100%;
        margin: 0;
        top: 50%;
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
      }
    }
    &.right {
      width: 60%;
      top: 20%;
      align-items: flex-ends;
      margin: 0 0 3rem 40%;
      @include media-breakpoint-max(md) {
        width: 100%;
        margin: 0;
        top: 50%;
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
      }
    }
    &.left {
      width: 60%;
      align-items: flex-start;
      margin: 0 40% 3rem 0;
      top: 20%;
      @include media-breakpoint-max(md) {
        width: 100%;
        margin: 0;
        top: 50%;
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
      }
    }
  }
  .banner-button {
    margin-top: 2rem;
    @include media-breakpoint-max(md) {
      width: calc(100% - 2rem);
    }
  }
}

.title {
  font-size: var(--text-size-xl);
}

.login-links {
  font-size: var(--text-size-small);
  a {
    color: var(--primary-color-dark);
  }
}
.page-head {
  h1 {
    color: var(--text-color-light);
  }
}
.card {
  background-color: transparent !important;

  img {
    @include media-breakpoint-min(md) {
      // aspect-ratio: 16 / 9;
    }

    &.blog-card-img {
      aspect-ratio: unset;
    }
  }
  .card-body {
    margin-top: 1rem;
    display: flex;
    align-content: space-between;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    color: white;
  }
}
.cards-4 {
  .card {
    border: 0;
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
.single-card {
  &.card {
    border: 0;
    border-radius: 0;
    border-top: 1px solid grey;
  }
}

.cards_style {
  width: -webkit-fill-available;
  @media (min-width: 576px) {
    flex: 1 0 0%;
  }
}

.font-0_7 {
  font-size: 0.7rem;
}
.font-1_4 {
  font-size: 1.4rem;
}
.bg-primary {
  background-color: var(--primary-color-dark) !important;
}
.hgs-logo {
  height: 3rem;
}

.navigation-col {
  list-style: none;
  display: flex;
  flex-direction: column;
}
.navigation-row {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.navigation-header {
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    @media (min-width: 990px) {
      flex-direction: row;
    }
  }
  a {
    color: var(--text-color-light);
    font-weight: 700;
    &:hover {
      color: white;
    }
  }
}
.welcome_user {
  display: flex;
  flex-direction: row;
}
.user_icon {
  height: 0.8rem;
}
.footer-links {
  list-style: none;
  display: flex;
  color: var(--primary-color-light);
  li {
    padding: 0 5px;
    border-right: 1px solid var(--footer-link-color);
    display: inline-flex;
    &:last-child {
      padding: 0 10px;
      border-right: 0;
      display: inline-flex;
    }
  }
  a {
    color: var(--primary-color-light);
    font-weight: var() var(--text-weight-titles);
  }
}

.contact-title {
  display: block;
  font-weight: 500;
  a {
    color: var(--primary-color-dark);
  }
}
.bg-purple {
  background-color: var(--secondary-color-dark);
}

.account-details {
  font-size: var(--text-size-medium);
  text-align: center;
}

@keyframes fadeInBody {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.form_label {
  font-size: var(--text-size-large);
  font-weight: 700;
  color: var(--primary-color-dark);
}
.home-lending-guide-img {
  padding: 0.25rem;
  border: 1px solid #dee2e6;
}
.activity-card a {
  color: black;
}
.dropdown-toggle {
  &:after {
    color: #ccc;
  }
}
.links-component {
  a {
    display: flex;
    flex-direction: column;
    i {
      color: var(--background-color-grey);
      font-size: var(--text-size-small);
    }
    &:hover {
      color: var(--background-color-dark);
      background: var(--text-color-light);
      i {
        color: var(--background-color-dark);
      }
    }
  }
}
.links-component.contact-card {
  span {
    font-size: 1.5rem;
  }
  a {
    background: var(--secondary-color-dark);
    color: var(--background-color-dark);
    border-color: var(--background-color-dark);
    &:hover {
      background: var(--secondary-color-light);
      color: var(--secondary-color-dark);
      transition: all 1800ms ease;
    }
  }
}

.img__wrap {
  position: relative;
  .img__description_layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--retail-catalog-desc-bg-color);
    color: var(--retail-catalog-desc-text-color);
    visibility: hidden;
    opacity: 0;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    /* transition effect. not necessary */
    transition: opacity 0.2s, visibility 0.2s;
  }
  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 0;
    border-color: transparent var(--secondary-color-light) transparent
      transparent;
    right: 0;
    top: 0;
    position: absolute;
  }
  &:hover {
    .img__img {
      opacity: 0.3;
    }
    .img__description_layer {
      visibility: visible;
      opacity: 1;
    }
    .img__description {
      transform: translateY(0);
    }
  }
  .img__description {
    transition: 0.2s;
    transform: translateY(1em);
  }
}
.cart-row {
  td:first-child img {
    width: 10rem;
    @media (max-width: 992px) {
      width: 5rem;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-group.quantity-input {
  clear: both;
  margin: 15px 0;
  position: relative;
  input {
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    margin: 0;
    outline: none;
    padding: 10px;
  }
  input[type="button"] {
    background-color: #eeeeee;
    min-width: 1.5rem;
    width: auto;
    transition: all 300ms ease;
  }
  .button-minus,
  .button-plus {
    font-weight: bold;
    height: 1.5rem;
    padding: 0;
    width: 1.5rem;
    position: relative;
  }
  .quantity-field {
    position: relative;
    height: 1.5rem;
    text-align: center;
    width: 2.5rem;
    display: inline-block;
    font-size: 13px;
    margin: 0 0 5px;
    resize: vertical;
  }
  .button-plus {
    left: -3px;
  }
}

@media (max-width: 800px) {
  .cart-table {
    thead {
      display: none;
    }

    tr,
    td {
      border: 0;
    }
    .cart-row {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid var(--color-grey-medium);
      td:first-child img {
        width: 15rem;
      }
      .cart-item-quantity {
        display: inline;
        float: inline-end;
      }
      .cart-item-price,
      .cart-item-amount {
        text-align: right !important;
      }
      td {
        width: 100%;
        padding: 0.5rem 0;
        &:last-child {
          padding-bottom: 1.5rem;
        }
      }
      td::before {
        content: attr(data-label);
        float: left;
      }
    }
  }
}
.checkout {
  .total {
    font-size: 1.25rem;
    padding-left: 0.5rem;
  }
  .shipping {
    font-size: var(--text-size-small);
    color: var(--color-grey-medium);
  }
  .cart-details {
    padding-top: 1rem;
    border-left: 1px;
    font-size: var(--text-size-medium);
    background-color: var(--background-color-grey);
  }
  .checkout-item-badge {
    --bs-badge-font-size: 0.5em;
    position: relative;
    top: -1.5rem;
    right: 0.5rem;
  }
}
.express_buttons {
  hr {
    display: inline-block;
    width: 30%;
    margin: 0 !important;
    padding: 0 !important;
  }

  span {
    width: 40% !important;
    display: inline-block;
    text-align: center;
  }
}
.Or_checkout {
  hr {
    display: inline-block;
    width: 40%;
    margin: 0 !important;
    padding: 0 !important;
  }

  span {
    width: 20% !important;
    display: inline-block;
    text-align: center;
  }
}

//blog style
.blog-list {
  display: none;
  @include media-breakpoint-min(lg) {
    display: block;
  }
  ul {
    li.nav-item {
      a {
        color: var(--primary-color-light) !important;
      }
    }
  }
}
a.blog-list-card.card {
  width: 33%;
  display: inline-block;
  border: 0;
}

.blog-list-card {
  color: var(--primary-color-light) !important;
  .card-body {
    color: var(--primary-color-light) !important;
  }
}
.blog-accordion {
  display: block;
  @include media-breakpoint-min(lg) {
    display: none;
  }
  .accordion-item {
    background-color: transparent;
    border: 0;
    color: var(--primary-color-light) !important;
  }
  .accordion-header {
    background-color: transparent;
    color: var(--primary-color-light) !important;
    button {
      background-color: transparent;
      color: var(--primary-color-light) !important;
    }
  }
}

.ReactModal__Content {
  background-image: var(--background-image) !important;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  @include media-breakpoint-min(lg) {
    width: 50%;
    margin: auto;
  }
  button.close {
    color: white;
    float: right;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 100%;
    align-items: stretch;
  }
}
.text-small * {
  font-size: var(--text-size-small) !important;
  h2 {
    font-weight: 700;
  }
}
.ReactModal__Overlay {
  z-index: 99;
  background: rgba(21, 21, 32, 0.9) !important;
}
.ReactCollapse--collapse {
  transition: height 500ms;
}
.m-2 {
  margin: 1rem;
}
.p-2 {
  padding: 1rem;
}
.pointer {
  cursor: pointer;
}
.feed-powered-by-es {
  display: none !important;
}

div.animate {
  animation: colorchange 10s infinite;
}

@keyframes colorchange {
  0% {
    background-color: red;
  }
  25% {
    background-color: yellow;
  }
  50% {
    background-color: blue;
  }
  75% {
    background-color: green;
  }
  100% {
    background-color: red;
  }
}

.card.card-horizontal {
  display: flex;
  flex-direction: row;
}
.text-primary {
  color: var(--text-color-light) !important;
}
